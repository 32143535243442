<!--
 * @Author: wh
 * @Date: 2020-05-25 21:55:00
 * @LastEditTime: 2020-06-28 18:07:24
 * @LastEditors: Please set LastEditors
 * @Description: p2p session
 * @FilePath: \Web\JT.Web\src\views\messageCenter\pages\page\components\dialogue\Session.vue
-->
<template>
    <div>
        <ul>
            <li
                class="noData ng-scope"
                v-if="sessionlistData.length<=0"
            >
                暂无最近联系人
            </li>
            <li
                v-for="session in sessionlistData"
                :key="session.id"
                :sessionId="session.id"
                @click="enterChat(session, session.id)"
                @contextmenu.prevent="openMenu($event, session)"
                :class="{'active': activeIndex===session.id}"
            >
                <div class="HeadPortrait">
                    <span class="final_reading" v-if="session.unReadCount > 0">{{ session.unReadCount }}</span>
                    <img v-if="session.AvatarUrl" :src="session.AvatarUrl" alt="">
                    <p v-else :style="`background: ${session.User_Gender === 0 ? '#fe86a8' : '#4da9ff'}`">
                        {{ session.name|getLastCharacter }}
                    </p>
                </div>
                <div class="information">
                    <div class="infoTitle">
                        <p :title="session.name">
                            <span>{{ session.name }}</span>
                        </p>
                        <p>{{ session.updateTimeShow }}</p>
                    </div>
                    <div class="infoContent">
                        <p class="fl">
                            {{ session.lastMsgShow }}
                        </p>
                        <b
                            v-show="session.unread > 0"
                            class="u-unread fr"
                        >{{ session.unread }}</b>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="showDropdown" class="menu_box" :style="`position: fixed; left: ${menuLeft}px; top: ${menuTop}px;`">
            <div v-if="DetailData.isPinned === false" @click="menuTopFun(1)">
                置顶
            </div>
            <div v-else @click="menuTopFun(2)">
                取消置顶
            </div>
            <div @click="menuSignFun">
                标为已读
            </div>
            <div @click="menuDelFun">
                删除
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/common/NIM/configs';
export default {
    data() {
        return {
            delSessionId: null,
            stopBubble: false,
            noticeIcon: config.noticeIcon,
            myPhoneIcon: config.myPhoneIcon,
            myGroupIcon: config.defaultGroupIcon,
            myAdvancedIcon: config.defaultAdvancedIcon,
            activeIndex: null,

            menuLeft: 0,
            menuTop: 0,
            showDropdown: false,
            DetailData: {},
        };
    },
    props: {
        sessionlistData: Array,
    },
    computed: {
        sysMsgUnread() {
            const temp = window.$globalHub.$store.state.sysMsgUnread;
            let sysMsgUnread = temp.addFriend || 0;
            sysMsgUnread += temp.team || 0;
            const customSysMsgUnread = window.$globalHub.$store.state.customSysMsgUnread;
            return sysMsgUnread + customSysMsgUnread;
        },
        userInfos() {
            return window.$globalHub.$store.state.userInfos;
        },
        myInfo() {
            return window.$globalHub.$store.state.myInfo;
        },
        myPhoneId() {
            return `${window.$globalHub.$store.state.userUID}`;
        },
        // sessionlist() {
        //     const sessionlist = window.$globalHub.$store.state.sessionlist.filter(item => {
        //         item.name = '';
        //         item.avatar = '';
        //         if (item.scene === 'p2p') {
        //             let userInfo = null;
        //             if (item.to !== this.myPhoneId) {
        //                 userInfo = this.userInfos[item.to];
        //             } else {
        //                 // userInfo = this.myInfo
        //                 // userInfo.alias = '我的手机'
        //                 // userInfo.avatar = `${config.myPhoneIcon}`
        //                 return false;
        //             }
        //             if (userInfo) {
        //                 item.name = util.getFriendAlias(userInfo);
        //                 item.avatar = userInfo.avatar;
        //             }
        //         } else if (item.scene === 'team') {
        //             let teamInfo = null;
        //             teamInfo = window.$globalHub.$store.state.teamlist.find(team => {
        //                 return team.teamId === item.to;
        //             });
        //             if (teamInfo) {
        //                 item.name = teamInfo.name;
        //                 item.avatar
        //       = teamInfo.avatar
        //       || (teamInfo.type === 'normal'
        //           ? this.myGroupIcon
        //           : this.myAdvancedIcon);
        //             } else {
        //                 item.name = `群${item.to}`;
        //                 item.avatar = item.avatar || this.myGroupIcon;
        //             }
        //         }
        //         const lastMsg = item.lastMsg || {};
        //         if (lastMsg.type === 'text') {
        //             item.lastMsgShow = lastMsg.text || '';
        //         } else if (lastMsg.type === 'custom') {
        //             item.lastMsgShow = util.parseCustomMsg(lastMsg);
        //         } else if (
        //             lastMsg.scene === 'team'
        //   && lastMsg.type === 'notification'
        //         ) {
        //             item.lastMsgShow = util.generateTeamSysmMsg(lastMsg);
        //         } else if (util.mapMsgType(lastMsg)) {
        //             item.lastMsgShow = `[${util.mapMsgType(lastMsg)}]`;
        //         } else {
        //             item.lastMsgShow = '';
        //         }
        //         if (item.updateTime) {
        //             item.updateTimeShow = util.formatDate(item.updateTime, true);
        //         }
        //         return item;
        //     });
        //     const p2pSession = sessionlist.filter(item => item.scene === 'p2p' );
        // return p2pSession;
        // },
    },
    watch: {
        tabIndex() {
            this.activeIndex = null;
            this.DetailData = {};
        },
    },
    mounted() {
        // 模拟外部点击
        document.addEventListener('click', e => {
            if (e.target.className !== 'menu_box') {
                this.DetailData = {};
                this.showDropdown = false;
            }
        });
    },
    created() {
        this.$imconn.getConversationlist({
            limit: 50,
            success: function (res) {
                console.log('getGroup', res);
                // uni.setStorage({
                //     key: 'listGroup',
                //     data: res.data,
                // });
                // that.readJoinedGroupName(res.data);
            },
            error: function (err) {
                console.log(err);
            },
        });
        this.$imconn.getGroup({
            limit: 50,
            success: function (res) {
                console.log('111111111111', res);
                // uni.setStorage({
                //     key: 'listGroup',
                //     data: res.data,
                // });
                // that.readJoinedGroupName(res.data);
            },
            error: function (err) {
                console.log(err);
            },
        });
    },
    methods: {
        enterChat(session, id) {
            this.$emit('clickContact', 'p2p-' + session.id, session);
            this.activeIndex = id;
        },
        readJoinedGroupName(joinedGroupList) {
            // const joinedGroupList = this.getStorageSync('listGroup');
            const groupList = joinedGroupList?.data || joinedGroupList || [];
            const groupName = {};
            groupList.forEach(item => {
                groupName[item.groupid] = item.groupname;
            });
            // this.groupName = groupName;
        },

        // 打开菜单
        openMenu(e, row) {
            this.DetailData = row;
            this.menuLeft = e.clientX + 10;
            this.menuTop = e.clientY;
            this.showDropdown = true;
        },
        // 置顶
        menuTopFun(flag) {
            const DetailData = this.DetailData;
            this.$WebIM.conn.pinConversation({
                conversationId: this.DetailData.id,
                conversationType: this.DetailData.type,
                isPinned: !this.DetailData.isPinned,
            }).then(() => {
                this.sessionlistData.forEach((item, i) => {
                    this.$message.success(flag === 1 ? '置顶成功' : '取消置顶成功');
                    if (DetailData.id === item.id) {
                        this.sessionlistData[i].isPinned = !DetailData.isPinned;
                    }
                });
            }).catch(() => {
                console.log('置顶失败错误');
            });
        },
        // 标为已读
        menuSignFun() {
            this.msgRead();
        },
        // 删除
        menuDelFun() {
            const that = this;
            const options = {
                // 会话 ID：单聊为对方的用户 ID，群聊为群组 ID。
                channel: this.DetailData.id,
                // 会话类型：（默认） `singleChat`：单聊；`groupChat`：群聊。
                chatType: this.DetailData.type,
                // 删除会话时是否同时删除服务端漫游消息。
                deleteRoam: true,
            };
            this.$WebIM.conn.deleteConversation(options).then(() => {
                this.$message.success('删除成功');
                setTimeout(() => {
                    that.$emit('clickContact', null, that.activeIndex === options.channel);
                    if (that.activeIndex === that.DetailData.id) {
                        that.activeIndex = null;
                    }
                }, 500);
            }).catch(e => {
                // 删除失败。
                console.log(e, '删除删除失败失败');
            });
            console.log('eee');
        },
        // 已读消息
        msgRead() {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/message_read/' + this.DetailData.id)
                .then(() => {
                    // 
                    this.$message.success('已读成功');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.menu_box
    width 1.3rem
    padding .1rem 0
    background #FFFFFF
    box-shadow 0px 0px 4px 0px rgba(0,0,0,0.3)
    z-index 99
    div
        font-size 14px
        font-family SourceHanSansCN, SourceHanSansCN
        font-weight 400
        color #333333
        width 1.1rem
        text-align left
        height .32rem
        line-height .32rem
        margin 0 .1rem
        padding 0 .1rem
        cursor pointer
        &:hover
            width 1.1rem
            border-radius 2px
            color #fff
            background linear-gradient(90deg, #3C9CFF 0%, #337CFF 100%)
.HeadPortrait
    position relative
    .final_reading
        color #fff
        position absolute
        z-index 1
        display flex
        justify-content center
        align-items center
        right -.05rem
        top .02rem
        min-width .32rem
        height .22rem
        font-size .16rem
        background #F64A2C
        border-radius 8px
        border 1px solid #FFFFFF
    p
        border-radius 2px !important
.information
    padding-top .04rem !important
    .infoTitle
        .p_box
            width 100%
            height 100%
            #name
                font-size .18rem
                padding-right 0.5rem
                position relative
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 500;
                color: #222222;
                .time
                    position absolute
                    right 0
                    color #666
                    font-size .16rem
            #message
                color #666
                font-size .14rem
            p
                height 50%
</style>
